import Grid from "@bit/mui-org.material-ui.grid";
import { motion } from "framer-motion";
import React from "react";
import styled, { css } from "styled-components";
import { Button } from "./Buttons";
import deleteImage from "../../../../static/media/delete.png";
import { useMainStore } from "../../../../store";

const Box = styled(motion.div)`
  background-color: ${(props) => props.theme.colors.grey};
  padding: 1em;
  border-radius: 10px;
  margin-bottom: 1.5em;
  min-height: 50px;

  ${(props) =>
    props.small &&
    css`
      min-height: 0px;
      padding: 0.5em;
      margin-bottom: 0em;
      cursor: pointer;
    `}
`;

const EstimationInput = ({ setAnswer, input, setInput, unit, prepend }) => {
  const { theme } = useMainStore();

  return (
    <div>
      <Box>
        <h2
          style={{
            fontSize: "3em",
            fontWeight: 900,
            textAlign: " right",
            margin: 0,
            color: theme.colors.purple,
          }}
        >
          {input && prepend ? `${unit}${input}` : input && `${input}${unit}`}
        </h2>
      </Box>
      <Grid spacing={2} container>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 0, 12].map((item) => (
          <Grid item xs={4}>
            <Box
              onClick={() => {
                if (item === 12) {
                  setInput((input) => input.slice(0, -1));
                } else if (item !== 10 && input?.length !== 3) {
                  setInput((input) =>
                    input !== undefined ? `${input}${item}` : `${item}`
                  );
                }
              }}
              small
              style={{
                backgroundColor:
                  item >= 10 ? theme.colors.grey : theme.colors.grey,
              }}
            >
              <h2
                style={{
                  fontSize: "1.5em",
                  fontWeight: 900,
                  margin: 0,
                  color: theme.colors.purple,
                  userSelect: "none",
                }}
              >
                {item !== 12 ? (
                  item
                ) : (
                  <img
                    alt="delete"
                    width={50}
                    height={"auto"}
                    src={deleteImage}
                  />
                )}
              </h2>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Button
        onClick={() => setAnswer(input)}
        fullWidth
        style={{ marginTop: "0.5em", fontSize: "1.5em" }}
        primary
      >
        Antwoord invullen
      </Button>
    </div>
  );
};

export default EstimationInput;
