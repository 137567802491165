import { motion } from "framer-motion";
import styled from "styled-components";

export const AnswersHolder = styled(motion.div)`
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  position: relative;
  margin: 0 auto;
  font-size: 1.3em;
  > div {
    padding: 15px;
    @media only screen and (max-width: 600px) {
      padding: 10px;
    }
    border-bottom: 1px solid #b2a1c7;
    &:last-child {
      border-bottom: none;
    }

    h3 {
      fontweight: 600;
      color: ${(props) => props.theme.colors.purpleDarker};
      margin: 0 0 0 15px;
      @media only screen and (max-width: 600px) {
        font-size: 1em;
        line-height: 1.5em;
      }
    }
  }
`;
