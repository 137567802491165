export const themes = {
  2023: {
    colors: {
      buttonText: "#2d4b94",
      background: "#E88D67",
      text: "#2d4b94",
      primary: "#ffd300",
      yellowLighter: "#ffd615",
      yellow: "#2d4b94",
      yellowShadow: "#1e356d",
      yellowTile: "#1e356d",
      purpleLighter: "#243486",
      purple: "#0D1821",
      purpleShadow: "#0D1821",
      purpleDarker: "#1e356d",
      orange: "#2d4b94",
      orangeDarker: "#bb7f3d",
      orangeLighter: "#fca853",
      green: "#FFA62B",
      greenShadow: "#2d4b94",
      greenDarker: "#2d4b94",
      beige: "#f2ecde",
      lightBlue: "#0D1821",
      blue: "#FFA62B",
      red: "#d15440",
      white: "#ffffff",
      grey: "#e9f0f5",
      greyDarker: "#bbc1c4",
      brown: "#fff",
      backgroundLight: "#FFFDDF",
      backgroundTransparent: "rgba(25,65,255,0.7)",
      goldGradient: `#FFA62B`,
      black: "#0D1821",
    },
  },
};
