import React, { useEffect } from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { motion } from "framer-motion";

import CashQuizLogo from "../../atoms/CashQuizLogo";
import { Button } from "../../atoms/Buttons";
import { useMainStore } from "../../../../../store";
import { transitionSpring } from "../../../../../default/pages/mainpages/SelectLesson";
import { TextLink } from "../../atoms/TextLink";

const Explanation = () => {
  const { setPhase, theme } = useMainStore();

  return (
    <>
      <CashQuizLogo exit />
      <motion.div>
        <Grid
          style={{
            height: "100vh",
          }}
          container
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Grid item xs={8} lg={6}>
            <motion.h2
              transition={{
                opacity: { delay: 0.2 },
                ...transitionSpring,
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              Uitleg
            </motion.h2>
            <motion.p
              transition={{
                opacity: { delay: 0.2 },
                ...transitionSpring,
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              style={{
                fontSize: "2.2em",
                lineHeight: "1.4em",
                color: theme.colors.purple,
              }}
            >
              De <strong>Cash Quiz</strong> bestaat uit{" "}
              <strong>12 vragen</strong> en <strong>4 stellingen</strong>. De
              filmpjes ondersteunen de vragen. Elk
              <strong> goed antwoord</strong> levert <strong>likes</strong> op.
              Het team dat de <strong>meeste likes</strong> verdient is{" "}
              <strong>de winnaar</strong> van de Cash Quiz.
            </motion.p>
            <motion.p
              transition={{
                opacity: { delay: 0.2 },
                ...transitionSpring,
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              style={{
                fontSize: "2.2em",
                lineHeight: "1.4em",
                color: theme.colors.purple,
              }}
            >
              <strong>Veel succes!</strong>
            </motion.p>
          </Grid>
        </Grid>
        <Grid
          style={{
            position: "absolute",
            bottom: "30px",
            paddingLeft: "30px",
            paddingRight: "30px",
          }}
          container
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <Button
              primary
              onClick={() => setPhase("scoresBegin")}
              exit={{ opacity: 0 }}
              whileHover={{
                scale: 1.1,
                delay: 0,
              }}
            >
              Verder
            </Button>
          </Grid>
        </Grid>
        <TextLink
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          whileHover={{
            scale: 1.1,
            cursor: "pointer",
          }}
          transition={{
            scale: { delay: 0 },
            default: 0.5,
          }}
          onClick={() => setPhase("tips")}
          navigationPrevPurple
          bottom={"50px"}
          left={"50px"}
          style={{
            position: "absolute",
          }}
        >
          terug
        </TextLink>
      </motion.div>
    </>
  );
};

export default Explanation;
