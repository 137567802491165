import React from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { motion } from "framer-motion";

import CashQuizLogo from "../../atoms/CashQuizLogo";
import { Button } from "../../atoms/Buttons";
import { theme } from "../../../../../App";
import { transitionSpring } from "./SelectLesson";
import { useSocketStore } from "../../../../../store/socketStore";
import { Background } from "../../atoms/Backgrounds";

const tips = [
  "Sluit alle lopende programma’s op de computer af voordat je de link opent.",
  "Gebruik bij voorkeur de browser Google Chrome.",
  "Hou de handleiding bij de hand en noteer de tussenstand per groepje op papier (loopt het digibord vast of valt de internetverbinding weg, dan kun je de scores handmatig verder bijhouden en het winnende groepje doorgeven via info@bankvoordeklas.nl).",
  "Zet het Digibord in ‘Volledig scherm modus’.",
];

const Tips = () => {
  const { setPhase, theme } = useSocketStore();

  return (
    <>
      <CashQuizLogo />
      <motion.div>
        <Grid
          style={{
            height: "100vh",
          }}
          container
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Grid item xs={8} lg={6}>
            <motion.h2
              transition={{
                opacity: { delay: 0.2 },
                ...transitionSpring,
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              Tips voor spelen op een Digibord
            </motion.h2>
            {tips.map((tip, index) => (
              <motion.p
                transition={{
                  opacity: { delay: 0.2 },
                  ...transitionSpring,
                }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                style={{
                  fontSize: "1.4em",
                  lineHeight: "1.4em",
                  color: theme.colors.purple,
                }}
              >
                <strong>Tip {index + 1}:</strong> {tip}
              </motion.p>
            ))}
          </Grid>
        </Grid>
        <Grid
          style={{
            position: "absolute",
            bottom: "30px",
            paddingLeft: "30px",
            paddingRight: "30px",
          }}
          container
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <Button
              black
              onClick={() => setPhase("explanation")}
              whileHover={{
                scale: 1.1,
                delay: 0,
              }}
            >
              Verder
            </Button>
          </Grid>
        </Grid>
      </motion.div>
      <Background initialBig background={"cloudBackgroundBigWhite"} />
    </>
  );
};

export default Tips;
