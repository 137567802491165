import { motion } from "framer-motion";
export const StartTitle = ({ title }) => {
  return (
    <motion.div
      style={{
        fontSize: "83px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "left",
      }}
      initial={{ opacity: 0, y: -150 }}
      animate={{ opacity: 1, y: [0, 15, 5, 0, 15] }}
      transition={{ duration: 0.6, ease: "easeInOut" }}
      exit={{ opacity: 0, y: 50 }}
    >
      <div
        style={{
          height: "50%",
        }}
      >
        {title.first}
      </div>{" "}
      <div
        style={{
          transform: "translateY(-20px)",
          height: "50%",
        }}
      >
        {title.second}
      </div>
    </motion.div>
  );
};
