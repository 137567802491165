import React from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { motion } from "framer-motion";
import styled, { css } from "styled-components";
import { useMainStore } from "../../../../store";
import { transitionSpring } from "../../../../default/pages/mainpages/SelectLesson";
import { Button } from "../atoms/Buttons";
import AnswerNormal from "./AnswerNormal";
import AnswerMultiple from "./AnswerMultiple";
import AnswerTrueFalse from "./AnswerTrueFalse";
import AnswerEstimation from "./AnswerEstimation";
import AnswerOpen from "./AnswerOpen";

const Modal = styled(motion.div)`
  width: ${(props) => (!props.estimation ? "" : "750px")};
  max-width: 80%;
  padding: ${(props) => (!props.estimation ? "1rem 3rem 3rem 3rem" : "0rem")};
  text-align: center;
  border-radius: 10px;
  @media only screen and (min-width: 600px) {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    background-color: #fff;
  }
  @media only screen and (max-width: 600px) {
    padding: 15px;
    width: 100%;
    max-width: 100%;
    height: 100%;
  }
`;

export const AnswerBox = styled(motion.button)`
  width: 125px;
  height: 125px;
  @media only screen and (max-width: 600px) {
    width: 90px;
    height: 90px;
  }
  border: none;
  border-radius: 0.4rem;
  background: ${(props) => props.theme.colors.goldGradient};
  box-shadow: 0 5px 0 ${(props) => props.theme.colors.yellowShadow};
  font-size: 4rem;
  @media only screen and (max-width: 600px) {
    font-size: 2rem;
  }
  font-weight: 600;
  font-family: Brevia;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  cursor: pointer;
  transition: background 0.2s ease;
  color: #a54f18;
  position: relative;
  z-index: 1;

  &:before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0.4rem;

    background: rgb(255, 231, 169);
    background: linear-gradient(
      90deg,
      rgba(255, 231, 169, 1) 0%,
      rgba(249, 171, 33, 1) 100%
    );
    z-index: -1;
    transition: opacity 0.2s linear;
    opacity: 0;
  }

  &:hover::before {
    opacity: 1;
  }

  &:active {
    box-shadow: none;
    transform: translateY(5px);
  }

  &:focus {
    outline: 0;
  }

  ${(props) =>
    props.disable &&
    css`
      background: ${props.theme.colors.purple};
      box-shadow: 0 5px 0 ${(props) => props.theme.colors.purpleShadow};
      span {
        color: ${props.theme.colors.white};
      }

      &:hover::before {
        opacity: 0;
      }

      &:hover {
        background: ${(props) => props.theme.colors.purpleShadow};
      }
    `}
`;

const AnswerModal = ({
  selectedPlayer,
  players,
  setPlayerAnswer,
  setSelectedPlayer,
  confirm,
  setConfirm,
  type,
  homework,
  small,
}) => {
  const { setPhase, questions, round, decision, setRound, theme } =
    useMainStore();

  const renderAnswerInput = () => {
    switch (type) {
      case "eenkeuze":
        return (
          <AnswerNormal
            setConfirm={setConfirm}
            setSelectedPlayer={setSelectedPlayer}
            setPlayerAnswer={setPlayerAnswer}
            selectedPlayer={selectedPlayer}
            questions={questions}
            round={round}
            players={players}
            homework={homework}
            small={small}
          />
        );
      case "meerkeuze":
        return (
          <AnswerMultiple
            setConfirm={setConfirm}
            setSelectedPlayer={setSelectedPlayer}
            setPlayerAnswer={setPlayerAnswer}
            selectedPlayer={selectedPlayer}
            questions={questions}
            round={round}
            players={players}
            homework={homework}
            small={small}
          />
        );
      case "schatting":
        return (
          <AnswerEstimation
            setConfirm={setConfirm}
            setSelectedPlayer={setSelectedPlayer}
            setPlayerAnswer={setPlayerAnswer}
            selectedPlayer={selectedPlayer}
            questions={questions}
            round={round}
            players={players}
            homework={homework}
            small={small}
          />
        );
      case "waarnietwaar":
        return (
          <AnswerTrueFalse
            setConfirm={setConfirm}
            setSelectedPlayer={setSelectedPlayer}
            setPlayerAnswer={setPlayerAnswer}
            selectedPlayer={selectedPlayer}
            questions={questions}
            round={round}
            players={players}
            homework={homework}
            small={small}
          />
        );
      case "open":
        return (
          <AnswerOpen
            setConfirm={setConfirm}
            setSelectedPlayer={setSelectedPlayer}
            setPlayerAnswer={setPlayerAnswer}
            selectedPlayer={selectedPlayer}
            questions={questions}
            round={round}
            players={players}
            homework={homework}
            small={small}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Grid container alignItems="center" justify="center">
      <Modal
        style={{ y: 0 }}
        estimation={type === "schatting" && !confirm}
        layout
        initial={{ opacity: 0, scale: 0.1 }}
        exit={{ opacity: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ ...transitionSpring }}
      >
        {confirm ? (
          <>
            <motion.h1
              style={{
                color: theme.colors.purple,
                fontSize: "2rem",
                fontFamily: "Brevia",
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ ...transitionSpring, delay: 0.4 }}
            >
              {homework
                ? round === 11
                  ? "Dit was de laatste vraag"
                  : "Klaar voor de volgende vraag?"
                : "Alle antwoorden zijn ingevuld"}
            </motion.h1>

            <Button
              black
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              whileHover={{
                scale: 1.05,
                cursor: "pointer",
              }}
              transition={{
                opacity: { delay: 0.6 },
                default: 2.5,
              }}
              onClick={() => {
                if (
                  !homework &&
                  players.every((player) =>
                    type === "meerkeuze"
                      ? player.answer.length > 0
                      : player.answer !== undefined
                  )
                ) {
                  setPhase(
                    decision ? "questionDecisionReveal" : "revealAnswer"
                  );
                } else {
                  if (round === 11) {
                    setPhase("end");
                  } else {
                    setRound(round + 1);
                    setPhase("quizStart");
                  }
                }
              }}
            >
              {homework
                ? round === 11
                  ? "Naar het eindscherm"
                  : "Volgende vraag"
                : "Bekijk antwoord"}
            </Button>
          </>
        ) : (
          renderAnswerInput()
        )}
      </Modal>
    </Grid>
  );
};

export default AnswerModal;
