import React, { useState, useEffect } from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { motion, useAnimation } from "framer-motion";

import { PanelQuestion } from "../../molecules/PanelQuestion";
import { LetterBox, numberToLetter } from "../../atoms/LetterBox";
import { Button, NavigationNext } from "../../atoms/Buttons";
import { transitionSpring } from "./SelectLesson";
import Topbar from "../../molecules/Topbar";
import { AnswersHolder } from "../../atoms/AnswersHolder";
import { useSocketStore } from "../../../../../store/socketStore";
import useWindowDimensions from "../../../../../hooks/useWindowDimensions";
import ProgressTeams from "../../molecules/ProgressTeams";
import TextReply from "../../molecules/TextReply";
import NotAllPlayersModal from "../../atoms/NotAllPlayersModal";

const variants3 = {
  show: (custom) => ({
    opacity: 1,
    y: 0,
    transition: { delay: custom * 0.2 + 1 },
  }),
};

const backgroundVariants = {
  peak: {
    x: -100,
    transition: { duration: 1, type: "spring" },
  },
  small: {
    transition: { duration: 1, type: "spring" },
  },
  exit: {
    x: "-100%",
    transition: { ...transitionSpring, delay: 0.5 },
  },
};

const ThesisPage = ({ homework, socket }) => {
  const {
    setPhase,
    setRound,
    questions,
    round,
    theme,
    roomPin,
    answerPhase,
    setAnswerPhase,
    thesisAnswers,
    resetPlayerAnswering,
    teams,
  } = useSocketStore();

  const answersPanelAnimation = useAnimation();
  const questionPanelAnimation = useAnimation();
  const questionAnimation = useAnimation();
  const backgroundAnimation = useAnimation();
  const CloudBackgroundDescriptionAnimation = useAnimation();
  const [notAllPlayers, setNotAllPlayers] = useState(false);
  const { small, height, md } = useWindowDimensions();
  let [timer, startTimer] = useState(false);

  useEffect(() => {
    socket.emit("thesis", { question: questions[round], round, roomPin });
    resetPlayerAnswering();
  }, []);

  const variants = {
    full: {
      justifyContent: "center",
      transition: { duration: 1, type: "spring" },
      width: questions[round].intro
        ? questions[round].question_layout === "introbig"
          ? "50%"
          : "70%"
        : "100%",
      left: questions[round].intro
        ? questions[round].question_layout === "introbig"
          ? "50%"
          : "30"
        : "0%",
      paddingLeft: "0",
      x: 0,
    },
    hide: {
      opacity: 0,
    },
  };

  const variants2 = {
    peak: {
      transition: { ...transitionSpring },
      width: "100%",
    },
    small: {
      transition: { ...transitionSpring },
      width: questions[round].question_layout === "introbig" ? "50%" : "30%",
      justifyContent: "center",
    },
    hide: {
      opacity: 0,
    },
  };

  useEffect(() => {
    if (!questions[round].intro) {
      setAnswerPhase("show");
    } else {
      setAnswerPhase(false);
    }
  }, [setAnswerPhase, questions, round]);

  useEffect(() => {
    let timeout;
    if (!answerPhase) {
      questionPanelAnimation.start("peak");
      timeout = setTimeout(() => {
        backgroundAnimation.start("peak");
      }, 2000);
    } else if (answerPhase === "show") {
      questionAnimation.start("show");
      answersPanelAnimation.start("full");
      questionPanelAnimation.start("small");
    } else if (answerPhase === "exit") {
      questionPanelAnimation.start("hide");
      answersPanelAnimation.start("hide");
    }
    return () => clearTimeout(timeout);
  }, [
    answerPhase,
    answersPanelAnimation,
    questionAnimation,
    questionPanelAnimation,
    CloudBackgroundDescriptionAnimation,
    backgroundAnimation,
    questions,
    roomPin,
    round,
    socket,
  ]);

  return (
    <>
      <motion.div
        style={{
          zIndex: 0,
          position: "absolute",
          width: "100vw",
          height: height,
          backgroundColor: theme.colors.background,
        }}
        animate={CloudBackgroundDescriptionAnimation}
        variants={backgroundVariants}
        // exit="exit"
      />
      <Topbar
        roomPin={roomPin}
        question={round + 1}
        noVideo={!questions[round].video}
        questionValue={questions[round].points}
        questionSoort={questions[round].soort}
      />
      <Grid container alignItems="center" justify="center">
        <Grid
          item
          xs={answerPhase ? 12 : 12}
          lg={answerPhase ? 8 : 8}
          style={{ position: answerPhase ? "relative" : "inherit" }}
        >
          <PanelQuestion
            variants={variants}
            animate={answersPanelAnimation}
            style={{
              x: 250,
              left: "30%",
              height: height,
              marginTop: "-100px",
              padding:
                questions[round].question_layout === "questiononly" ? 0 : "",
              justifyContent:
                questions[round].question_layout === "questiononly"
                  ? "center"
                  : "inherit",
            }}
          >
            {answerPhase === "show" && (
              <Grid
                container
                alignItems={questions[round].intro ? "center" : "center"}
                justify="center"
                xs={8}
                lg={"auto"}
              >
                <Grid
                  item
                  style={{
                    width: "100%",
                    marginLeft: questions[round].intro ? "30px" : "0",
                  }}
                >
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 1 }}
                    exit={{ opacity: 0, transition: { delay: 0 } }}
                  >
                    <TextReply>
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            "<span class='stelling'>Stelling:</span> <br />" +
                            questions[round].stelling,
                        }}
                      />
                    </TextReply>
                    <AnswersHolder>
                      {JSON.parse(questions[round].stelling_answers).map(
                        (answer, index) =>
                          answer && (
                            <motion.div
                              style={{
                                opacity: 0,
                                y: -30,
                              }}
                              animate={questionAnimation}
                              variants={variants3}
                              custom={index}
                              key={answer}
                            >
                              <Grid container alignItems="center">
                                <Grid item xs={2} sm={1}>
                                  <LetterBox
                                    style={{
                                      opacity: 1,
                                      y: 0,
                                      backgroundColor: theme.colors.lightBlue,
                                    }}
                                    boxShadowColor="#B2A1C7"
                                  >
                                    {numberToLetter(index)}
                                  </LetterBox>
                                </Grid>
                                <Grid item xs={10} sm={11}>
                                  <motion.h3
                                    style={{
                                      fontFamily: "proxima-nova",
                                      fontWeight: "normal",
                                      opacity: 1,
                                      y: 0,
                                    }}
                                  >
                                    {answer}
                                  </motion.h3>
                                </Grid>
                              </Grid>
                            </motion.div>
                          )
                      )}
                    </AnswersHolder>
                  </motion.div>
                </Grid>
              </Grid>
            )}
          </PanelQuestion>
        </Grid>
      </Grid>
      <Grid
        style={{
          position: "absolute",
          bottom: "30px",
          paddingLeft: "30px",
          paddingRight: "30px",
        }}
        container
        direction="row"
        alignItems="center"
        justify="center"
      >
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <motion.div
            transition={{ transitionSpring, delay: 5 }}
            initial={{ y: 300 }}
            animate={{ y: 0 }}
            exit={{ y: 300, transition: { ...transitionSpring, delay: 0 } }}
          >
            <Button
              whileHover={{
                scale: 1.03,
                delay: 0,
              }}
              black
              onClick={() => {
                if (teams.length !== thesisAnswers.length) {
                  setNotAllPlayers(true);
                } else {
                  setPhase("thesisAnswers");
                }
              }}
              exit={{ opacity: 0 }}
            >
              Bekijk de uitkomst
            </Button>
          </motion.div>
        </Grid>
      </Grid>
      <motion.div
        initial={{ x: 75, opacity: 1 }}
        animate={{ x: 0 }}
        transition={{ delay: 2 }}
        exit={{ x: 75, transition: { delay: 0 } }}
        style={{
          height: "calc(100vh - 60px)",
          alignItems: "center",
          display: "flex",
        }}
      >
        <ProgressTeams teams={teams} playersAnswered={thesisAnswers} md={md} />
      </motion.div>
      <NotAllPlayersModal
        notAllPlayers={notAllPlayers}
        setNotAllPlayers={setNotAllPlayers}
        socket={socket}
        thesis
        roomPin={roomPin}
        timer={timer}
        currentPhase={answerPhase ? "answerPhase" : ""}
      />
    </>
  );
};

export default ThesisPage;
