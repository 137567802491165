import React, { useState, useEffect } from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { useForm } from "react-hook-form";
import { AnimatePresence, motion } from "framer-motion";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Background } from "../../../themes/2023/components/atoms/Backgrounds";
import { useMainStore } from "../../../store";
import { Input } from "../../../themes/2023/components/atoms/Input";
import { HeaderTwo } from "../../../themes/2023/components/atoms/Typography";
import Switch from "../../../themes/2023/components/atoms/Switch";
import { Button } from "../../../themes/2023/components/atoms/Buttons";
import Dropdown from "../../../themes/2023/components/atoms/Dropdown";
import { TextLink } from "../../../themes/2023/components/atoms/TextLink";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { useGAEventsTracker } from "../../../hooks/useGAEventsTracker";
import { transitionSpring } from "./SelectLesson";

const schema = yup.object().shape({
  jaar: yup.string().required("Dit veld is verplicht"),
  niveau: yup.string().required("Dit veld is verplicht"),
  school_name: yup.string().required("Dit veld is verplicht"),
  postcode: yup
    .string()
    .required("Dit veld is verplicht")
    .matches(
      /^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/i,
      "Dit is geen geldige postcode"
    ),
  teacher: yup.string().required("Dit veld is verplicht"),
  email_teacher: yup
    .string()
    .email("Dit is geen geldig email adres")
    .required("Dit veld is verplicht"),
});

const NoCodeForm = () => {
  const { height } = useWindowDimensions();
  const [title, setTitle] = useState("");
  const { setPhase, setGameID, theme, typeHost, typeQuiz } = useMainStore();
  const [guestTeacher, setGuestTeacher] = useState(false);
  const [banks, setBanks] = useState();
  const [niveau, setNiveau] = useState();
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });
  const GAEventsTracker = useGAEventsTracker("cashquiz");

  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER}banks`)
      .then((res) => res.json())
      .then((result) => {
        setBanks(result);
      });
  }, [setBanks]);

  const onSubmit = (data) => {
    Object.keys(data).map(
      (k) => (data[k] = typeof data[k] == "string" ? data[k].trim() : data[k])
    );
    data.bank = title;
    data.host = typeHost;
    data.quiztype = typeQuiz;

    fetch(process.env.REACT_APP_SERVER + "score/set-game-no-code", {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((result) => {
        GAEventsTracker("game gestart zonder code");
        setGameID(result.gameid);
        setPhase("lobby");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      <motion.div exit={{ opacity: 0, overflow: "scroll" }}>
        <Grid
          style={{
            height: "100vh",
            textAlign: "center",
            overflow: "scroll",
          }}
          container
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Grid item xl={10} lg={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-around"
                spacing={2}
              >
                <Grid item xs={12}>
                  <motion.h1
                    layout
                    transition={{ ...transitionSpring }}
                    initial={{ opacity: 0, transition: { delay: 0.2 } }}
                    animate={{ opacity: 1 }}
                    style={{
                      margin: 0,
                      fontSize: height < 700 ? "2em" : "3em",
                    }}
                  >
                    Vul gegevens van school in
                  </motion.h1>
                </Grid>
                <Grid style={{ textAlign: "left" }} item xs={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <HeaderTwo
                          layout
                          transition={{ ...transitionSpring }}
                          style={{
                            fontSize: height < 700 ? "1em" : "1.5em",
                            fontWeight: 600,
                          }}
                      >
                        Leerjaar
                      </HeaderTwo>
                      <Input
                          small={height < 700}
                          layout
                          transition={{ ...transitionSpring }}
                          initial={{ opacity: 0, transition: { delay: 0.2 } }}
                          animate={{ opacity: 1 }}
                          placeholder="Bijv. Leerjaar 1"
                          name="jaar"
                          ref={register({ required: true })}
                      />
                      {errors.jaar && (
                          <span
                              style={{
                                color: theme.colors.red,
                                marginTop: 10,
                                display: "inline-block",
                              }}
                          >
                          {errors.jaar?.message}
                        </span>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <HeaderTwo
                          layout
                          transition={{ ...transitionSpring }}
                          style={{
                            fontSize: height < 700 ? "1em" : "1.5em",
                            fontWeight: 600,
                          }}
                      >
                        Niveau
                      </HeaderTwo>
                      <Dropdown
                          small={height < 700}
                          label="Niveau"
                          title={niveau}
                          setTitle={setNiveau}
                          name="niveau"
                          items={["Vmbo","Mavo", "Havo", "Vwo", "Lwoo", "PRO", "overig"]}
                      />
                        {errors.niveau && (
                            <span
                                style={{
                                    color: theme.colors.red,
                                    marginTop: 10,
                                    display: "inline-block",
                                }}
                            >
                          {errors.niveau?.message}
                        </span>
                        )}
                    </Grid>
                    <Grid item xs={6}>
                      <HeaderTwo
                        layout
                        transition={{ ...transitionSpring }}
                        style={{
                          fontSize: height < 700 ? "1em" : "1.5em",
                          fontWeight: 600,
                        }}
                      >
                        Schoolnaam
                      </HeaderTwo>
                      <Input
                        small={height < 700}
                        layout
                        transition={{ ...transitionSpring }}
                        initial={{ opacity: 0, transition: { delay: 0.2 } }}
                        animate={{ opacity: 1 }}
                        name="school_name"
                        ref={register({ required: true })}
                      />
                      {errors.school_name && (
                        <span
                          style={{
                            color: theme.colors.red,
                            marginTop: 10,
                            display: "inline-block",
                          }}
                        >
                          {errors.school_name?.message}
                        </span>
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      <HeaderTwo
                        layout
                        transition={{ ...transitionSpring }}
                        style={{
                          fontSize: height < 700 ? "1em" : "1.5em",
                          fontWeight: 600,
                        }}
                      >
                        Postcode school
                      </HeaderTwo>
                      <Input
                        small={height < 700}
                        layout
                        transition={{ ...transitionSpring }}
                        initial={{ opacity: 0, transition: { delay: 0.2 } }}
                        animate={{ opacity: 1 }}
                        name="postcode"
                        ref={register({ required: true })}
                      />
                      {errors.postcode && (
                        <span
                          style={{
                            color: theme.colors.red,
                            marginTop: 10,
                            display: "inline-block",
                          }}
                        >
                          {errors.postcode?.message}
                        </span>
                      )}
                    </Grid>
                    <Grid item xs={8}>
                      <HeaderTwo
                        layout
                        transition={{ ...transitionSpring }}
                        style={{
                          fontSize: height < 700 ? "1em" : "1.5em",
                          fontWeight: 600,
                        }}
                      >
                        Naam leerkracht
                      </HeaderTwo>
                      <Input
                        small={height < 700}
                        layout
                        transition={{ ...transitionSpring }}
                        initial={{ opacity: 0, transition: { delay: 0.2 } }}
                        animate={{ opacity: 1 }}
                        name="teacher"
                        ref={register({ required: true })}
                      />
                      {errors.teacher && (
                        <span
                          style={{
                            color: theme.colors.red,
                            marginTop: 10,
                            display: "inline-block",
                          }}
                        >
                          {errors.teacher?.message}
                        </span>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <HeaderTwo
                        small={height < 700}
                        layout
                        transition={{ ...transitionSpring }}
                        style={{
                          fontSize: height < 700 ? "1em" : "1.5em",
                          fontWeight: 600,
                        }}
                      >
                        E-mail leerkracht
                      </HeaderTwo>
                      <Input
                        small={height < 700}
                        layout
                        transition={{ ...transitionSpring }}
                        initial={{ opacity: 0, transition: { delay: 0.2 } }}
                        animate={{ opacity: 1 }}
                        name="email_teacher"
                        ref={register({ required: true })}
                      />
                      {errors.email_teacher && (
                        <span
                          style={{
                            color: theme.colors.red,
                            marginTop: 10,
                            display: "inline-block",
                          }}
                        >
                          {errors.email_teacher?.message}
                        </span>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <motion.div
                        layout
                        transition={{ ...transitionSpring }}
                        initial={{ opacity: 0, transition: { delay: 0.2 } }}
                        animate={{ opacity: 1 }}
                        style={{ height: "100%", marginTop: 20 }}
                      >
                        <Grid
                          container
                          style={{
                            height: "100%",
                            flexWrap: "nowrap",
                            alignItems: "center",
                          }}
                          alignItems="center"
                        >
                          <Grid item>
                            <Switch
                              isOn={guestTeacher}
                              setIsOn={setGuestTeacher}
                            />
                          </Grid>
                          <Grid item>
                            <HeaderTwo
                              layout
                              transition={{ ...transitionSpring }}
                              style={{
                                fontWeight: 400,
                                fontSize: "1.2em",
                                marginLeft: "1em",
                                marginBottom: 0,
                              }}
                            >
                              Wij spelen de Cash Quiz na online bezoek{" "}
                              <strong>gastdocent</strong>
                            </HeaderTwo>
                          </Grid>
                        </Grid>
                      </motion.div>
                    </Grid>
                    <AnimatePresence>
                      {guestTeacher && (
                        <>
                          <Grid item xs={4}>
                            <motion.div
                              transition={{ ...transitionSpring }}
                              initial={{ y: -20, opacity: 0 }}
                              animate={{ y: 0, opacity: 1 }}
                              exit={{ y: -20, opacity: 0 }}
                            >
                              <HeaderTwo
                                style={{
                                  fontSize: height < 700 ? "1em" : "1.5em",
                                  fontWeight: 600,
                                }}
                              >
                                Bank
                              </HeaderTwo>
                              <Dropdown
                                small={height < 700}
                                title={title}
                                setTitle={setTitle}
                                name="bank"
                                ref={register({ required: true })}
                                items={banks}
                              />
                            </motion.div>
                          </Grid>
                          <Grid item xs={8}>
                            <motion.div
                              transition={{ ...transitionSpring }}
                              initial={{ y: -20, opacity: 0 }}
                              animate={{ y: 0, opacity: 1 }}
                              exit={{ y: -20, opacity: 0 }}
                            >
                              <HeaderTwo
                                style={{
                                  fontSize: height < 700 ? "1em" : "1.5em",
                                  fontWeight: 600,
                                }}
                              >
                                Naam gastdocent
                              </HeaderTwo>
                              <Input
                                small={height < 700}
                                name="gastdocent"
                                ref={register({ required: true })}
                              />
                            </motion.div>
                          </Grid>
                          <Grid item xs={8}>
                            <motion.div
                              transition={{ ...transitionSpring }}
                              initial={{ y: -20, opacity: 0 }}
                              animate={{ y: 0, opacity: 1 }}
                              exit={{ y: -20, opacity: 0 }}
                            >
                              <HeaderTwo
                                style={{
                                  fontSize: height < 700 ? "1em" : "1.5em",
                                  fontWeight: 600,
                                }}
                              >
                                E-mail gastdocent
                              </HeaderTwo>
                              <Input
                                small={height < 700}
                                name="email"
                                ref={register({ required: true })}
                              />
                            </motion.div>
                          </Grid>
                        </>
                      )}
                    </AnimatePresence>
                    <Grid item xs={12}>
                      <motion.div
                        layout
                        transition={{ ...transitionSpring }}
                        initial={{ opacity: 0, transition: { delay: 0.2 } }}
                        animate={{ opacity: 1 }}
                      >
                        <Grid container justify="center">
                          <Grid item>
                            <Button
                              type="submit"
                              transition={{ ...transitionSpring }}
                              primary
                            >
                              Start de Cash Quiz
                            </Button>
                          </Grid>
                        </Grid>
                      </motion.div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </motion.div>
      <TextLink
        whileHover={{
          borderBottom: "1px solid #fff",
          cursor: "pointer",
          opacity: 1,
        }}
        onClick={() => setPhase("personalCode")}
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.5 }}
        bottom={"50px"}
        left={"50px"}
        style={{
          position: "absolute",
        }}
      >
        terug
      </TextLink>
      <Background big background={"cloudBackground"} />
    </>
  );
};

export default NoCodeForm;
