import { motion } from "framer-motion";
import React from "react";
import { useMainStore } from "../../../../store";
import { DottedBorder } from "../../../../static/2023/DottedBorder";
import Money from "../../../../static/2023/Money.png";
import CoinStack from "../../../../static/2023/Coinstack.png";
import { PaperMoney } from "../../../../static/2023/PaperMoney";
import { transitionSpring } from "../../../../default/pages/mainpages/SelectLesson";
import CoinJar from "../../../../static/2023/CoinJar.svg";
import MoneyImage from "../../../../static/2023/MoneyTree.svg";
import Coins from "../../../../static/2023/Coins.svg";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";

export const Background = ({
  background,
  big,
  initialBig,
  exit,
  opacity,
  exitOpacity,
}) => {
  const { theme, showMoney } = useMainStore();
  const window = useWindowDimensions();
  const backgrounds = {
    cloudBackground: [
      {
        size: "120vh",
        transform: "translate(-50%, -60%)",
        background: theme.colors.background,
      },
      {
        size: "100vh",
        transform: "translate(-110%, -65%)",
        background: theme.colors.background,
      },
      {
        size: "120vh",
        transform: "translate(-10%, -70%)",
        background: theme.colors.background,
      },
      {
        size: "60vh",
        transform: "translate(10%, -70%)",
        background: theme.colors.backgroundLight,
      },
      {
        size: "90vh",
        transform: "translate(-55%, -55%)",
        background: theme.colors.backgroundLight,
      },
      {
        size: "80vh",
        transform: "translate(-120%, -65%)",
        background: theme.colors.backgroundLight,
      },
    ],
    cloudBackgroundBig: [
      {
        size: "120vh",
        transform: "translate(-50%, -60%)",
        background: theme.colors.background,
      },
      {
        size: "100vh",
        transform: "translate(-110%, -65%)",
        background: theme.colors.background,
      },
      {
        size: "120vh",
        transform: "translate(-10%, -70%)",
        background: theme.colors.background,
      },
      {
        size: "60vh",
        transform: "translate(10%, -70%)",
        background: theme.colors.backgroundLight,
      },
      {
        size: "90vh",
        transform: "translate(-55%, -55%)",
        background: theme.colors.backgroundLight,
      },
      {
        size: "80vh",
        transform: "translate(-120%, -65%)",
        background: theme.colors.backgroundLight,
      },
    ],
    cloudBackgroundBigWhite: [
      {
        size: "200vh",
        transform: "translate(-50%, -70%)",
        background: "#fff",
      },
      {
        size: "80vh",
        transform: "translate(-130%, -65%)",
        background: "#fff",
      },
      {
        size: "200vh",
        transform: "translate(-10%, -80%)",
        background: "#fff",
      },
      {
        size: "75vh",
        transform: "translate(10%, -80%)",
        background: theme.colors.backgroundLight,
      },
      {
        size: "90vh",
        transform: "translate(-55%, -65%)",
        background: theme.colors.backgroundLight,
      },
      {
        size: "70vh",
        transform: "translate(-120%, -75%)",
        background: theme.colors.backgroundLight,
      },
    ],
  };

  const getAnimationInitial = () => {
    if (big) return { y: 0, scale: 1 };
    else if (opacity) return { opacity: 0 };
  };

  const getAnimation = () => {
    if (big) return { scale: 1.2 };
    else if (opacity) return { opacity: 1 };
  };

  const getExitAnimation = () => {
    if (exit)
      return {
        y: -1000,
        transition: {
          delay: 0.8,
          ease: [0.58, 0.02, 0.45, 1],
          duration: 0.6,
        },
      };
    else if (exitOpacity) {
      return {
        // opacity: 0,
        transition: {
          delay: 0.8,
          ease: [0.58, 0.02, 0.45, 1],
          duration: 0.6,
        },
      };
    }
  };

  return (
    <>
      <motion.div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -2,
          scale: initialBig ? 1.1 : 1,
        }}
        initial={getAnimationInitial()}
        animate={getAnimation()}
        transition={{ duration: opacity ? 1 : 0.5 }}
        exit={getExitAnimation()}
      >
        <DottedBorder />
      </motion.div>
      {showMoney && (
        <>
          <div
            style={{
              position: "absolute",
              top: "55vh",
              left: window.width < 1550 ? "5vw" : "18vw",
              zIndex: 3,
            }}
          >
            <img
              height={window.width < 1550 ? "100%" : "150%"}
              width={window.width < 1550 ? "100%" : "150%"}
              src={Coins}
              alt="SVG logo image"
            />
          </div>
          <div
            style={{
              position: "absolute",
              top: "20vh",
              left: "70vw",
            }}
          >
            <img
              height={"150%"}
              width={"150%"}
              src={MoneyImage}
              alt="SVG logo image"
            />
          </div>
          <div
            style={{
              position: "absolute",
              top: "20vh",
              left: window.width < 1550 ? "5vw" : "10vw",
            }}
          >
            <img
              height={"200%"}
              width={"200%"}
              src={CoinJar}
              alt="SVG logo image"
            />
          </div>
        </>
      )}
    </>
  );
};
