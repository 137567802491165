import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { useMainStore } from "../../../../store";

const TimerBar = ({ timer, totalTime }) => {
  const { small } = useWindowDimensions();
  const { theme } = useMainStore();
  const clockControls = useAnimation();
  const numberControls = useAnimation();

  useEffect(() => {
    clockControls.start({ rotateZ: [0, 360] });
    numberControls.start({
      scale: timer < 10 ? [1, 1.1, 1] : [1, 1, 1],
      fontSize: timer < 10 ? "3rem" : "1.5rem",
      color: timer < 10 ? theme.colors.black : theme.colors.black,
      transition: { duration: 1 },
    });
  }, [timer]);

  return (
    <>
      <motion.div
        animate={{ opacity: timer < 10 ? 1 : 0 }}
        style={{
          borderRadius: "50%",
          width: 40,
          height: 40,
          background: theme.colors.white,
          marginRight: 32,
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          position: "relative",
          boxShadow: `0px 3px 0px ${theme.colors.orange}`,
        }}
      >
        <div
          style={{
            position: "absolute",
            width: 10,
            height: 5,
            top: -3,
            backgroundColor: theme.colors.orange,
            borderRadius: "0.5rem",
          }}
        />

        <div
          style={{
            position: "absolute",
            width: 5,
            height: 5,
            top: 17,
            backgroundColor: theme.colors.orange,
            borderRadius: "100%",
          }}
        />
        <motion.div
          animate={clockControls}
          style={{
            height: "50%",
            width: 3,
            borderRadius: "5px",
            background: theme.colors.orange,
            transformOrigin: "bottom center",
          }}
        />
      </motion.div>
      <motion.div
        style={{
          position: "relative",
          display: "inline-flex",
          float: "right",
          marginRight: "30px",
        }}
      >
        <motion.h3
          layout
          style={{
            fontSize: small ? "1em" : "1.5em",
            marginTop: 0,
            marginBottom: "20px",
            whiteSpace: "nowrap",
          }}
        >
          <small style={{ fontFamily: "proxima-nova", fontWeight: "normal" }}>
            nog
          </small>
          {"  "}
          <motion.span
            initial={false}
            style={{
              color: theme.colors.black,
              display: "inline-block",
              marginRight: timer < 10 ? 12 : 10,
              width: 25,
            }}
            animate={numberControls}
          >
            {timer}{" "}
          </motion.span>
          <small style={{ fontFamily: "proxima-nova", fontWeight: "normal" }}>
            seconden
          </small>
        </motion.h3>
        <motion.div
          animate={{
            width:
              timer === "full"
                ? "100%"
                : `${Math.round((timer / totalTime) * 100)}%`,
          }}
          style={{
            backgroundColor: theme.colors.black,
            height: 10,
            position: "absolute",
            zIndex: 10,
            bottom: 0,
            left: 0,
          }}
        >
          <span
            style={{
              float: "right",
              height: "10px",
              marginRight: "15px",
              lineHeight: "30px",
              color: theme.colors.white,
            }}
          ></span>
        </motion.div>
        <motion.div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            height: 10,
            backgroundColor: theme.colors.white,
            opacity: 1,
            width: "100%",
            zIndex: 1,
          }}
        ></motion.div>
      </motion.div>
    </>
  );
};

export default TimerBar;
