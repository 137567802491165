export const DottedBorder = () => {
  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        width: "100vw",
        height: "100%",
        minHeight: "1000px",
      }}
    >
      {" "}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1436 720"
        fill="none"
      >
        <g clipPath="url(#a)">
          <path fill="#FFFEF8" d="M0 0h1436v720H0z" />
          <path
            fill="#E88D67"
            d="M1136 305c0 204.898-181.101 371-404.5 371S327 509.898 327 305 508.101-66 731.5-66 1136 100.102 1136 305Z"
          />
          <path
            fill="#E88D67"
            d="M1485 218c0 204.898-181.1 371-404.5 371C857.101 589 676 422.898 676 218s181.101-371 404.5-371c223.4 0 404.5 166.102 404.5 371ZM662 257c0 180.045-159.163 326-355.5 326S-49 437.045-49 257 110.163-69 306.5-69 662 76.955 662 257Z"
          />
          <path fill="#E88D67" d="M-49-153h1534v302H-49v-302Z" />
          <path
            fill="#e89c7c"
            d="M620 244c0 157.401-127.599 285-285 285S50 401.401 50 244 177.599-41 335-41 620 86.599 620 244Z"
          />
          <path
            fill="#e89c7c"
            d="M1043 279.5C1043 460.926 895.926 608 714.5 608S386 460.926 386 279.5C386 98.075 533.074-49 714.5-49S1043 98.075 1043 279.5Z"
          />
          <path
            fill="#e89c7c"
            d="M1215 223c0 114.323-92.68 207-207 207-114.323 0-207-92.677-207-207s92.677-207 207-207c114.32 0 207 92.677 207 207Z"
          />
          <path
            stroke="#0D1821"
            strokeDasharray="3 23"
            strokeLinecap="round"
            strokeOpacity=".24"
            strokeWidth="11"
            d="M1446.5 407c-40.33 82.833-182.4 234.2-440 195-96 77.667-338.8 187-570-19-76 33.667-277.1 58.8-433.5-106"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h1436v720H0z" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
